import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Strong, Box, Span, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanbod/analyse"} />
		<Helmet>
			<title>
				dimple - Analyse
			</title>
			<meta name={"description"} content={"Vanuit een goed begrip van de huidge situatie, kijken we naar wat beter kan.  We schetsen de gewenste toekomstige situatie.  Op basis hiervan tekenen we de behoeften en krijlijnen uit voor een toekomstige digitale werkplek.  "} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="menu" exact-active-match={false} />
		</Components.MenuHam>
		<Section padding="20px 0 20px 0" sm-padding="60px 0 60px 0" background="#ffffff">
			<Text margin="0px 0px 0px 0px" font="--headline2" text-align="center">
				Flow- , informatie- en behoeftenanalyse{"\n\n\n\n"}
			</Text>
			<Text margin="0px 0px 0px 0px" />
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 80px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_thought_process_re_om58.svg?v=2023-05-23T16:32:07.367Z" display="block" max-width="100%" margin="20px 0px 0px 0px" />
				<Box min-width="100px" min-height="100px">
					<Box
						width="100%"
						display="flex"
						flex-direction="row"
						margin="0px 0px 0px 0px"
						lg-margin="0px 4% 15px 0px"
						sm-width="100%"
						sm-margin="0px 0px 30px 0px"
					>
						<Box>
							<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left" />
							<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
								"Een systeem is meer dan de som van haar delen".{"  "}
								<br />
								<br />
								Om verbetering (bv. qua samenwerking, productviteit, kwaliteit, werkvoldoening, omzet...) van het "globale systeem" (jouw team of onderneming) mogelijk te maken, hebben we{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									eerst het systeem vandaag te begrijpen
								</Strong>
								.{"  "}
								<br />
								<br />
								Van hieruit bedenken we (stappen naar) de{" "}
								<Strong>
									gewenste toekomstige situatie.
								</Strong>
								{"  "}En deze bepalen dan de krijtlijnen voor een digitale werkplek.
								<br />
								<br />
								Deze oefening is{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									vooral van belang bij complexere ecosystemen
								</Strong>
								: grotere teams, grote variatie in processen, diensten en producten.
								<br />
								Voor eenvoudigere omgevingen zal dit bij aanvang van elk traject, in verkorte vorm gebeuren.{"  "}
							</Text>
						</Box>
					</Box>
				</Box>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				/>
				<Components.TerugNaarAanbod />
				<Components.MaakAfspraak href="mailto:dirk@dimple.be" />
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Huidige situatie
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							{" "}We nemen een{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								deep-dive{" "}
							</Strong>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								in je huidige manier van werken en onderzoeken:
							</Span>
							{"  "}
							<br />
							- welke diensten en producten biedt je aan?  en aan wie?
							<br />
							- welke concepten en termen hanteer je?
							<br />
							- via welke processen creëer jij (of je team) waarde?
							<br />
							- uit welke processtappen bestaat elk proces?
							<br />
							- wie (intern en/of extern) is hierbij betrokken?
							<br />
							- welke informatie is hierbij noodzakelijk? of wenselijk?{"  "}
							<br />
							- welke tools ondersteunen deze processen?
							<br />
							- wat zijn typische doorlooptijden en bottlenecks?  in welke omstandigheden?
							<br />
							<br />
							We doen dit o.m. door de techniek van value-stream-mapping.  Ook maken we gebruik van context-, sequence, activity diagrams waar relevant.  We hanteren hierbij steeds een systemische blik.{"   "}
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Gewenste toekomstige situatie
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Door een kritische blik op de huidige situatie, kijken we we hoe verschillende vormen van "verspilling"  (tijd, aandacht of middelen) kunnen vermijden of verminderen.{"  "}
							<br />
							We tekenen de{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								gewenste{" "}
							</Strong>
							(toekomstige){" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								werkwijze{" "}
							</Strong>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								uit
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									.{"  "}
								</Strong>
							</Span>
							<br />
							{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Behoeften digitale werkplek{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Op basis van de gewenste toekomstige situatie wordt duidelijk welke kernprocessen evt. dienen  verbeterd worden.  Een digitale werkplek kan deze flows ondersteunen.  Alvorens te starten met het ontwerp willen we in kaart brengen welke{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								behoeften{" "}
							</Strong>
							een tool minimaal moet kunnen invullen.   Met welke{"  "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								randvoorwaarden{" "}
							</Strong>
							{"\n"}(aantal gebruikers, groei, volume, groeiverwachtingen, ... )
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"\n\n"}
							</Strong>
							moet rekening gehouden worden?  Welke behoeften zijn prioritair?  Via welke roadmap willen we deze invullen?{"  "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Troeven>
			<Override slot="text7">
				Een digitale werkplek is géén doel op zich.   Het is{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					een middel
				</Strong>
				{" "}om mensen te helpen om hun doelen te bereiken. dimple heeft ruime ervaring met het ontwerp en de bouw van gebruikersvriendelijke digitale webapplicaties binnen een professionele context.
			</Override>
			<Override slot="text5">
				dimple ontwikkelt digitale werkplekken{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					afgestemd op jouw doelen, noden en werkcontext
				</Strong>
				{" "}(en niet omgekeerd!).   Een digitale werkplek is gemaakt om mee te groeien met jouw zaak.  Met elke nieuwe uitdaging en behoefte die zich aandient.{"        "}
			</Override>
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2024 Dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});